<template>
	<dialog-component v-if="showPostLikesDialog" class="consumers-with-likes-dialog">
		<template v-if="!isLoading" #header-title> {{ `${consumersWithLikes?.data.length} ${totalLikesString}` }}</template>
		<infinite-scroll v-if="!isLoading" :load-function="getMoreConsumersWithLikes">
			<user-with-likes
				:show-options-button="false"
				v-for="fan in consumersWithLikes?.data"
				:key="fan.consumer.id"
				v-bind="fan.consumer"
				:total_likes_by_posts="fan.total_likes_by_post"
				:user-link="{
					name: 'consumer-profile',
					params: {
						nickname: fan.consumer.nickname
					}
				}"
				@click-link="clickLink"
			/>
		</infinite-scroll>
		<template v-else>
			<placeholder-user-with-likes v-for="i in 1" :key="`puwl${i}`" />
		</template>
	</dialog-component>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { usePostStore } from '@/stores/post'
import UserWithLikes from '@/components/UserWithLikes.vue'
import InfiniteScroll from '@/components/InfiniteScroll.vue'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import PlaceholderUserWithLikes from '@/components/placeholders/PlaceholderUserWithLikes.vue'

export default {
	name: 'ConsumersWithLikesDialog',
	components: { InfiniteScroll, UserWithLikes, DialogComponent, PlaceholderUserWithLikes },
	props: {
		showPostLikesDialog: {
			type: Boolean
		}
	},
	watch: {
		showPostLikesDialog(newVal) {
			if (newVal) {
				window.addEventListener('popstate', this.handlePopState)
			} else {
				window.removeEventListener('popstate', this.handlePopState)
			}
		}
	},
	computed: {
		...mapState(usePostStore, ['consumersWithLikes', 'isLoading']),
		totalLikesString() {
			return this.$t('like', this.consumersWithLikes?.data.length)
		}
	},
	methods: {
		...mapActions(usePostStore, ['getMoreConsumersWithLikes']),
		closeDialog(link = false) {
			this.$emit('close', link)
		},
		handlePopState() {
			this.closeDialog()
		},
		clickLink() {
			this.closeDialog(true)
		}
	},
	beforeUnmount() {
		window.removeEventListener('popstate', this.handlePopState)
	}
}
</script>

<style lang="scss">
.consumers-with-likes-dialog {
	& .dialog {
		display: flex;
		flex-direction: column;
		overflow: unset;
	}
	.dialog-header {
		min-height: 40px;
	}
	& .dialog-body {
		overflow-y: auto;
		height: 100%;
		& > div {
			overflow-y: auto;
			height: 100%;
		}
	}
	& .user-with-likes {
		padding: 8px 0;
	}
	& .avatar-circle {
		margin-right: 12px;
	}
}
</style>
