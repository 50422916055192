import { defineStore } from 'pinia'
import isMobile from 'ismobilejs'
import { putProfileLocale } from '@/http/common/index'

function getIsMobileDevice() {
	const { userAgent } = navigator
	return isMobile(userAgent).any
}

export const useCommonStore = defineStore('common', {
	state: () => ({
		isPowerSaveMode: false,
		deferredPrompt: null,
		tenant: import.meta.env.VITE_TENANT,
		isMobileDevice: getIsMobileDevice(),
		isLoading: false,
		locale: 'ru',
		localeMap: new Map([
			['ru', 'russian'],
			['en', 'english'],
			['uz', 'uzbek']
		]),
		onlineStatusMap: new Map()
	}),
	getters: {
		getUserOnlineStatus(state) {
			return (userRole, userId) => state.onlineStatusMap.get(`${userRole}-${userId}`)
		}
	},
	actions: {
		setDeferredPrompt(e) {
			this.deferredPrompt = e
		},
		removeDeferredPrompt() {
			this.deferredPrompt = null
		},
		setIsLoadingState(state) {
			this.isLoading = state
		},
		setLocale(locale) {
			this.locale = locale
		},
		async setProfileLocale(locale) {
			await putProfileLocale(locale)
		},
		setUserOnlineStatus(userRole, userId, status) {
			this.onlineStatusMap.set(`${userRole}-${userId}`, status)
		},
		clearUserOnlineStatusData() {
			this.onlineStatusMap.clear()
		},
		mapGirlsWithTotalLikesByPostOnlineStatus(data) {
			data.forEach((girl) => {
				this.setUserOnlineStatus(girl.girl.role, girl.girl.id, girl.girl.online)
			})
		},
		mapUserOnlineStatus(data) {
			data.forEach((girl) => {
				this.setUserOnlineStatus(girl.role, girl.id, girl.online)
			})
		},
		mapConsumersListOnlineStatus(data) {
			data.forEach((list) => {
				list.consumers.forEach((consumer) => {
					this.setUserOnlineStatus(consumer.role, consumer.id, consumer.online)
				})
			})
		},
		mapCommentEntityOnlineStatus(data) {
			data.forEach((comment) => {
				this.setUserOnlineStatus(comment.author.role, comment.author.id, comment.author.online)
			})
		},
		mapPostCommentEntityOnlineStatus(data) {
			data.forEach((el) => {
				this.setUserOnlineStatus(el.comment.author.role, el.comment.author.id, el.comment.author.online)
				this.setUserOnlineStatus(el.post.author.role, el.post.author.id, el.post.author.online)
			})
		},
		mapConsumerEntityOnlineStatus(data) {
			data.forEach((consumer) => {
				this.setUserOnlineStatus(consumer.consumer.role, consumer.consumer.id, consumer.consumer.online)
			})
		},
		mapPostEntityOnlineStatus(data) {
			data.forEach((post) => {
				this.setUserOnlineStatus(post.author.role, post.author.id, post.author.online)
				if (post.comment) {
					this.setUserOnlineStatus(post.comment.role, post.comment.consumer_id, post.comment.online)
				}
			})
		},
		mapThreadEntityOnlineStatus(data) {
			if (Array.isArray(data)) {
				data.forEach((thread) => {
					this.setUserOnlineStatus(
						thread.resources.recipient.provider_alias,
						thread.resources.recipient.base.id,
						thread.resources.recipient.options.online
					)
				})
			} else {
				this.setUserOnlineStatus(
					data.resources.recipient.provider_alias,
					data.resources.recipient.base.id,
					data.resources.recipient.options.online
				)
			}
		}
	}
})
