import { http } from '@/http/index'

async function searchGirls(params) {
	return http.get('/girls/search', { params })
}

async function getMoreGirls(url) {
	return http.get(url).then((response) => response.data)
}

export { searchGirls, getMoreGirls }
