<template>
	<div class="btn-wrap">
		<button
			type="button"
			@click="onClick"
			:class="[btnClass, { invert: isSubscribed, disabled: subscribedTill }]"
			:disabled="isWaitingSubscriptionResult"
		>
			<template v-if="isWaitingSubscriptionResult">
				<loader-component size="24px" />
			</template>
			<template v-else>
				<div v-html="subscribedText" />
			</template>
		</button>
		<confirmation-dialog
			v-if="dialogVisible"
			btn-accept-text="subscription.accept"
			btn-decline-text="subscription.decline"
			@accept="onUnsubscribe"
			@decline="hideDialog"
			@close="hideDialog"
		>
			<template #title>
				{{ $t('subscription.wantUnsubscribe') }}
			</template>
			{{ $t('subscription.unsubscribeDescription') }}
		</confirmation-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGirlStore } from '@/stores/girl'
import { useHomeStore } from '@/stores/home'
import { useConsumerStore } from '@/stores/consumer'
import { useSearchStore } from '@/stores/search'
import { useProfileStore } from '@/stores/profile'
import { useSubscribeStore } from '@/stores/subscribe'
import { useModalStore } from '@/stores/modal'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import subscriptionsMixin from '@/mixins/subscriptionsMixin'
import { toDayAndMonth } from '@/helpers/dateFormat'

export default {
	name: 'SubscribeButton',
	components: { ConfirmationDialog, LoaderComponent },
	props: {
		id: { type: Number, default: 0 },
		isGirlProfile: { type: Boolean, default: false },
		isSubscribed: { type: Boolean, default: false },
		isWaitingSubscriptionResult: { type: Boolean, default: false },
		isSubscribeRenewable: { type: Boolean, required: true },
		subscribedTill: { type: [String, null], default: null },
		btnClass: { type: String, default: '' },
		avatar: { type: String, default: '' },
		girlNickname: { type: String, default: '' }
	},
	data() {
		return {
			dialogVisible: false
		}
	},
	mixins: [subscriptionsMixin],
	computed: {
		...mapState(useProfileStore, {
			consumerId: 'id',
			subscriptionsData: 'subscriptionsData',
			girl_subscription_restricted: 'girl_subscription_restricted'
		}),
		...mapState(useModalStore, {
			openSubLimitErrorModal: 'openSubLimitErrorModal',
			openSubLimitModal: 'openSubLimitModal'
		}),
		subscribeLink() {
			const host = this.$config.apiUrl
			if (this.isGuest) {
				return `${host}guest/girls/${this.id}/subscribe?lang=${this.$i18n.locale}`
			}
			return `${host}consumers/${this.consumerId}/girls/${this.id}/subscribe?lang=${this.$i18n.locale}`
		},
		girlSubscriptionPeriod() {
			return this.$config.girlSubscriptionPeriod || this.$t('month')
		},
		allowFollowing() {
			return this.$config.allowFollowing
		},
		subscribedText() {
			if (this.allowFollowing) {
				return this.isSubscribed
					? `${this.$t('youAreFan')} ${this.girlNickname}`
					: `${this.$t('becomeAFan')} ${this.girlNickname}`
			}
			if (!this.isSubscribed) {
				if (
					this.$config.showSubscriptionPriceInGirlProfile &&
					this.$config.girlSubscriptionPrice &&
					this.isGirlProfile
				) {
					return this.$t('subscription.subscribeFor', {
						price: this.$config.girlSubscriptionPrice,
						period: this.girlSubscriptionPeriod
					})
				}
				return this.$t('subscription.subscribe')
			}
			if (this.isSubscribeRenewable) {
				return this.$t('subscription.subscribed')
			}
			return this.$t('subscription.validUntil', { date: toDayAndMonth(this.subscribedTill) })
		},
		isGuest() {
			return JSON.parse(this.$auth.remember())?.role === 'guest'
		}
	},
	methods: {
		...mapActions(useGirlStore, {
			removeBlurFromGirlPosts: 'removeBlurFromPosts',
			subscribe: 'subscribe'
		}),
		...mapActions(useHomeStore, {
			resetFilter: 'resetFilter',
			removeBlurFromHomePosts: 'removeBlurFromPosts'
		}),
		...mapActions(useConsumerStore, {
			removeBlurFromConsumerPosts: 'removeBlurFromPosts'
		}),
		...mapActions(useSearchStore, {
			removeBlurFromSearchPosts: 'removeBlurFromPosts'
		}),
		...mapActions(useSubscribeStore, ['checkSubscribeLimitState']),
		showDialog() {
			this.dialogVisible = true
		},
		hideDialog() {
			this.dialogVisible = false
		},
		onClick() {
			if (this.isGuest) {
				window.location.href = this.subscribeLink
				return
			}
			if (!this.isSubscribeRenewable) {
				return
			}
			if (this.isSubscribed) {
				this.showDialog()
				return
			}
			if (this.girl_subscription_restricted) {
				this.openSubLimitErrorModal({ avatar: this.avatar })
				return
			}
			if (this.checkSubscribeLimitState()) {
				if (this.hasAtLeastOneRenewableSubscription()) {
					this.openSubLimitModal({
						id: this.id,
						avatar: this.avatar
					})
					return
				}
				this.openSubLimitErrorModal({ avatar: this.avatar })
			}
			window.location.href = this.subscribeLink
		},
		async onUnsubscribe() {
			await this.$_stopPaidSubscription(this.id)
			this.hideDialog()
		},
		hasAtLeastOneRenewableSubscription() {
			return this.subscriptionsData?.data?.girls?.find((item) => item?.is_renewable === false) !== undefined
		}
	}
}
</script>

<style lang="scss" scoped>
.btn.disabled {
	cursor: default;
	&:hover {
		border-color: #363b4a;
	}
}
</style>
