import { http } from '@/http/index'

async function getThreadByConsumerNickname(consumerNickname) {
	return http.get(`/messenger/thread/consumer/${consumerNickname}`).then((response) => response.data.data)
}

async function getThreadByGirlNickname(girlNickname) {
	return http.get(`/messenger/thread/girl/${girlNickname}`).then((response) => response.data.data)
}

export { getThreadByConsumerNickname, getThreadByGirlNickname }
