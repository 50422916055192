import { http } from '@/http/index'

async function getGirlsWithTotalLikesByPosts(consumerId, params) {
	return http.get(`/consumers/${consumerId}/girls-with-likes`, { params }).then((response) => response.data)
}

async function getMoreGirlsWithTotalLikesByPosts(url, params) {
	return http.get(url, { params }).then((response) => response.data)
}

export { getGirlsWithTotalLikesByPosts, getMoreGirlsWithTotalLikesByPosts }
