import { defineStore } from 'pinia'
import { useAuth } from '@websanova/vue-auth'
import { useCommonStore } from '@/stores/common'
import messengerApi from '@/http/messenger'
import girlApi from '@/http/girl/index'
import echo from '@/sockets/echo'
import AppConfig from '@/config.class'
import Resumable from 'resumablejs'
import messageSound from '@/assets/sounds/notify_tone.mp3'
import threadSound from '@/assets/sounds/message_tone.mp3'

const audioMessage = new Audio(messageSound)
const audioThread = new Audio(threadSound)

export const useMessengerStore = defineStore('messenger', {
	state: () => ({
		auth: useAuth(),
		messagesList: [],
		messagesMeta: null,
		messagesController: null,
		messagesLoaded: false,
		isLoadingMessages: false,
		girlProfile: null,
		roomsList: [],
		roomsNextPageId: null,
		roomsLoaded: false,
		loadingRooms: false,
		nextPageLoaded: false,
		activeThread: null,
		recommendedList: [],
		searchedUsers: [],
		roomName: '',
		containerScrolled: false,
		unreadThreadsCount: 0,
		welcomeMsgTimeout: 0,
		isRoomsInitialized: false,
		roomsFilter: null,
		commonStore: useCommonStore()
	}),
	getters: {
		getRecipientId: (state) => state.activeThread?.resources?.recipient?.provider_id || state.activeThread?.provider_id,
		getSearchParams(state) {
			const params = {}
			if (state.roomsFilter) params.filter = state.roomsFilter
			return params
		}
	},
	actions: {
		checkFiltersMatch(thread) {
			const isAdult = thread.resources.recipient.base.is_adult
			const isSubscribed = thread.participants_has_subscription
			const isFollower = thread.participants_has_following && !isSubscribed && !isAdult
			const isFollowerWithCard = thread.participants_has_following && !isSubscribed && isAdult

			if (isSubscribed && this.roomsFilter.is_subscribed === 1) return true
			if (isFollower && this.roomsFilter.is_adult_and_follower === 0) return true
			if (isFollowerWithCard && this.roomsFilter.is_adult_and_follower === 1) return true
			return false
		},
		setRoomsFilter(filter) {
			this.roomsFilter = filter
		},
		resetRoomList() {
			this.roomsList = []
			this.roomsNextPageId = null
			this.isRoomsInitialized = false
		},
		resetRoomsFilter() {
			if (this.roomsFilter) {
				this.roomsFilter = null
				this.resetRoomList()
			}
		},
		setIsRoomsInitialized(state) {
			this.isRoomsInitialized = state
		},
		setUnreadThreadsCount(count) {
			this.unreadThreadsCount = Number(count)
		},
		decrementUnreadThreadsCount() {
			if (this.unreadThreadsCount > 0) this.unreadThreadsCount -= 1
		},
		async getUnreadThreadsCount() {
			try {
				const { unread_threads_count } = await messengerApi.getUnreadThreadsCount()
				this.unreadThreadsCount = unread_threads_count
			} catch (e) {
				throw new Error(e)
			}
		},
		async getRoomsList(params, reset = false) {
			try {
				if (!this.isRoomsInitialized || reset) {
					this.roomsLoaded = false
					this.loadingRooms = true
					const result = await messengerApi.getThreadsList(params)
					this.roomsNextPageId = result.meta.next_page_id
					if (reset) {
						this.roomsList = result.data
					} else {
						this.roomsList = Object.values(
							[...this.roomsList, ...result.data].reduce((acc, el) => {
								acc[el.id] = el
								return acc
							}, {})
						)
					}
					this.commonStore.mapThreadEntityOnlineStatus(result.data)
				}
				this.setIsRoomsInitialized(true)
			} catch (e) {
				throw new Error(e)
			} finally {
				this.loadingRooms = false
			}
		},
		async getMoreRooms(params) {
			try {
				if (this.roomsNextPageId) {
					const result = await messengerApi.getMoreThreads(this.roomsNextPageId, params)
					this.roomsList = Object.values(
						[...this.roomsList, ...result.data].reduce((acc, el) => {
							acc[el.id] = el
							return acc
						}, {})
					)
					this.roomsNextPageId = result.meta.next_page_id
					this.commonStore.mapThreadEntityOnlineStatus(result.data)
				}
			} catch (e) {
				throw new Error(e)
			} finally {
				if (!this.roomsNextPageId) {
					this.roomsLoaded = true
				}
			}
		},
		async getRecommendedGirls() {
			try {
				const res = await messengerApi.getRecommendedListGirl()
				this.recommendedList = res.map((r) => ({ r, ...r.provider }))
				if (this.recommendedList.data?.length) {
					this.commonStore.mapThreadEntityOnlineStatus(this.recommendedList.data)
				}
			} catch (e) {
				throw new Error(e)
			}
		},
		async getRecommendedConsumers() {
			try {
				const res = await messengerApi.getRecommendedListConsumer()
				this.recommendedList = res.map((r) => ({ r, ...r.provider }))
				if (this.recommendedList.data?.length) {
					this.commonStore.mapThreadEntityOnlineStatus(this.recommendedList.data)
				}
			} catch (e) {
				throw new Error(e)
			}
		},
		async searchUsers(value) {
			if (!value || value.length < 2) {
				this.searchedUsers = []
				return
			}
			try {
				const result = await messengerApi.search(value.replace(/[&\\#,+()$~%.'":*?]/g, ''))
				this.searchedUsers = result.data
			} catch (e) {
				throw new Error(e)
			}
		},
		resetSearch() {
			this.searchedUsers = []
		},
		resetActiveRoom() {
			this.activeThread = null
			this.roomName = ''
		},
		toggleActiveThread(thread) {
			this.activeThread = thread
		},
		updateMessages(data) {
			this.messagesList = data
		},
		updateRoomsNewThread(newThread) {
			this.roomsList = Object.values(
				[newThread, ...this.roomsList].reduce((acc, el) => {
					acc[el.id] = el
					return acc
				}, {})
			)
		},
		newMockMessage(msg) {
			this.messagesList.splice(this.messagesList.length, 0, msg)
		},
		newMessage(msg, id) {
			if (this.activeThread && this.activeThread.id === msg.thread_id) {
				let index = -1
				if (msg.type_verbose === 'PAID_MESSAGE') {
					index = this.messagesList.findIndex((m) => m?.paid_message?.id === msg.paid_message.id)
				} else if (msg.temporary_id) {
					index = this.messagesList.findIndex((m) => String(m.temporary_id) === String(msg.temporary_id))
				}

				if (index === -1) {
					this.messagesList.splice(this.messagesList.length, 0, msg)
				} else {
					this.messagesList.splice(index, 1, msg)
				}
			}

			this.updateRoomMessage(msg)

			if (msg.owner_id !== id) {
				this.playSound()
			}
		},
		updateEditedMessage(msg) {
			const index = this.messagesList.findIndex((m) => m.id === msg.id)
			if (this.messagesList[index]) this.messagesList.splice(index, 1, msg)
			this.updateRoomMessage(msg)
		},
		async updateRoomMessage(msg) {
			if (this.roomsList.length === 0) return
			const index = this.roomsList.findIndex((thread) => thread.id === msg.thread_id)

			if (index === -1) {
				await this.getThreadById(msg.thread_id)
				return
			}

			const room = this.roomsList[index]
			room.resources.latest_message = msg
			this.roomsList.splice(index, 1)
			this.roomsList.splice(0, 0, room)

			if (msg.owner.name === room.resources?.recipient?.name) {
				room.unread = true
				room.unread_count += 1
			}
		},
		async removeRoomMessage(msg) {
			const index = this.messagesList.findIndex((m) => m.id === msg.message_id)
			if (index !== -1) {
				this.messagesList.splice(index, 1)
			}

			const roomIndex = this.roomsList.findIndex((room) => room.id === msg.thread_id)
			if (roomIndex !== -1) {
				if (this.roomsList[roomIndex].resources.latest_message.id === msg.message_id) {
					const res = await messengerApi.getMessages({ threadId: msg.thread_id })
					const arr = [...res.data.reverse()]
					if (res.data.length) {
						this.roomsList[roomIndex].resources.latest_message = arr[res.data.length - 2]
					}
				}
			}
		},
		removeThread(data) {
			const index = this.roomsList.findIndex((thread) => thread.id === data.thread_id)
			if (index !== -1) {
				this.roomsList.splice(index, 1)
			}
		},
		threadAvatar(data) {
			const index = this.roomsList.findIndex((thread) => thread.id === data.thread.id)
			if (index !== -1) {
				this.roomsList[index].avatar = data.thread.avatar
			}
		},
		async newThread(data) {
			await this.getThreadById(data.thread.id)
			this.playSound()
		},
		async markRead(threadId) {
			const room = this.roomsList.find((r) => r.id === threadId)
			room.unread = false
			await messengerApi.markRead(threadId)
			room.unread_count = 0
		},
		async updateReactions(data) {
			const idx = this.messagesList.findIndex((msg) => msg.id === data.message_id)
			if (idx !== -1) {
				const originalMessage = this.messagesList[idx]
				originalMessage.reactions = await messengerApi.getReactions(originalMessage.thread_id, data.message_id)
				this.messagesList.splice(idx, 1, originalMessage)
			}
		},
		async threadRead(data) {
			const room = this.roomsList.find((thread) => thread.id === data.thread_id)
			room.resources.latest_message.is_read = true
			this.decrementUnreadThreadsCount()
			this.messagesList
				.filter(
					(message) =>
						message?.owner_id === room.resources.latest_message.owner_id && message?.thread_id === data.thread_id
				)
				.map((message) => {
					message.is_read = true
					return message
				})
		},
		playSound() {
			if (this.activeThread) {
				audioMessage.play()
			} else {
				audioThread.play()
			}
		},
		updateUploadingProgress(temId, progressValue) {
			let messageIndex = null
			const msg = this.messagesList.find((m, i) => {
				if (m.temporary_id === temId) {
					messageIndex = i
					return m
				}
				return null
			})
			if (messageIndex !== -1) {
				msg.progress = progressValue
				this.messagesList.splice(messageIndex, 1, msg)
			}
		},
		async getNewMessages(signal) {
			const { unread, unread_count } = await messengerApi.getThread(this.activeThread.id)
			if (unread) {
				const res = await messengerApi.getMessages({ threadId: this.activeThread.id }, signal)
				const messages = res.data
				const newMessages = messages.slice(0, unread_count)
				this.updateMessages([...this.messagesList, ...newMessages.reverse()])
			}
		},
		async sendMultimediaMessage(threadId, params) {
			await messengerApi.sendMultimediaMessage(threadId, params)
		},
		setWelcomeMsgTimeout(timeout = 0) {
			this.welcomeMsgTimeout = timeout
		},
		async getThreadById(id) {
			const newThread = await messengerApi.getThread(id)
			if (this.roomsFilter && !this.checkFiltersMatch(newThread)) return
			this.roomsList.splice(0, 0, newThread)
		},
		async getMessages({ room }) {
			if (room?.id && !this.isLoadingMessages) {
				this.isLoadingMessages = true
				this.messagesController = new AbortController()
				const { signal } = this.messagesController
				let result = null
				if (this.messagesMeta && this.messagesMeta.next_page_route) {
					result = await messengerApi.getMessages({ nextPageRoute: this.messagesMeta.next_page_route }, signal)
				} else {
					result = await messengerApi.getMessages({ threadId: room.id }, signal)
					if (this.isConsumer) {
						await this.getCurrentGirlProfile(room?.resources?.recipient?.provider_id || room?.provider_id)
					}
				}

				const { data, meta } = result
				this.messagesMeta = meta

				if (data.length === 0 || data.length < this.messagesMeta.per_page) {
					setTimeout(() => {
						this.messagesLoaded = true
					}, 0)
				}

				if (room.resources.latest_message) {
					this.updateMessages([...data.reverse(), ...this.messagesList])
				}

				echo.subscribeThread(room.id)
				this.isLoadingMessages = false
			}
		},
		async getGirlProfile() {
			try {
				this.girlProfile = await girlApi.getProfile(this.getRecipientId)
			} catch (e) {
				throw new Error(e)
			}
		},
		async locateThread(userRole, userId) {
			try {
				return await messengerApi.locateThread(userRole, userId)
			} catch (e) {
				throw new Error(e)
			}
		},
		async initializeThread(userRole, userId) {
			try {
				return await messengerApi.initializeThread(userRole, userId)
			} catch (e) {
				throw new Error(e)
			}
		},
		async locateAndInitializeThread(userRole, userId) {
			try {
				let threadId = null
				const { thread_id, recipient } = await messengerApi.locateThread(userRole, userId)
				threadId = thread_id
				if (!thread_id) {
					const newThread = await messengerApi.initializeThread(userRole, userId)
					threadId = newThread.id
				}
				return { threadId, recipient }
			} catch (e) {
				throw new Error(e)
			}
		},
		async locateAndInitializeThreadWithWelcomeMessage(userRole, userId) {
			try {
				const { thread_id } = await messengerApi.locateThread(userRole, userId)
				if (!thread_id) {
					const newThread = await messengerApi.initializeThread(userRole, userId)
					if (AppConfig.chatGirlWelcomeMsg) {
						const welcomeMessage = await this.initializeWelcomeMsg(newThread.id, userId)
						if (welcomeMessage) {
							this.setWelcomeMsgTimeout(welcomeMessage?.timeout_sec)
						}
					}
				}
			} catch (e) {
				throw new Error(e)
			}
		},
		async getThreadByConsumerNickname(consumerNickname) {
			try {
				const thread = await messengerApi.getThreadByConsumerNickname(consumerNickname)
				this.commonStore.mapThreadEntityOnlineStatus(thread)
				this.updateRoomsNewThread(thread)
				this.toggleActiveThread(thread)
				this.roomName = thread.name
			} catch (e) {
				throw new Error(e)
			}
		},
		async getThreadByGirlNickname(girlNickname) {
			try {
				const thread = await messengerApi.getThreadByGirlNickname(girlNickname)
				this.commonStore.mapThreadEntityOnlineStatus(thread)
				this.updateRoomsNewThread(thread)
				this.toggleActiveThread(thread)
				this.roomName = thread.name
			} catch (e) {
				throw new Error(e)
			}
		},
		async initializeWelcomeMsg(threadId, girlId) {
			try {
				return messengerApi.initializeWelcomeMsg({
					thread_id: threadId,
					girl_id: girlId
				})
			} catch (e) {
				throw new Error(e)
			}
		},
		async setMessageReaction(threadId, messageId, reactionUnicode) {
			try {
				await messengerApi.setReaction(threadId, messageId, { reaction: reactionUnicode })
			} catch (e) {
				throw new Error(e)
			}
		},
		async removeMessageReaction(threadId, messageId, reactionId) {
			try {
				await messengerApi.removeReaction(threadId, messageId, reactionId)
			} catch (e) {
				throw new Error(e)
			}
		},
		async editMessageText(threadId, messageId, newMessageText) {
			await messengerApi.editMessage(threadId, messageId, { message: newMessageText })
		},
		async sendTextMessage(threadId, message) {
			await messengerApi.sendTextMessage(threadId, message)
		},
		async sendImageMessage(threadId, imageMessage) {
			await messengerApi.sendImageMessage(threadId, imageMessage)
		},
		sendVideoMessage(threadId, temporaryId, videoFile) {
			const uploader = new Resumable({
				target: `${AppConfig.apiUrl}messenger/threads/${threadId}/videos`,
				query: { temporary_id: temporaryId },
				maxChunkRetries: 1,
				maxFileSize: AppConfig.maxVideoSize,
				testChunks: false,
				fileType: ['mp4', 'mov', 'avi'],
				fileParameterName: 'video',
				headers: {
					Authorization: `Bearer ${this.auth.token()}`,
					Accept: 'application/json'
				}
			})

			uploader.on('fileAdded', async () => {
				await uploader.upload()
			})

			uploader.on('fileProgress', (file) => {
				const progress = Math.trunc(file.progress().toFixed(2) * 100)
				this.updateUploadingProgress(temporaryId, progress)
			})

			uploader.addFile(videoFile)
		},
		async muteNeurosexter(threadId) {
			try {
				const { girl_send_messages_enabled } = await messengerApi.muteNeurosexter(threadId)
				const thread = this.roomsList.find((r) => r.id === threadId)
				thread.girl_send_messages_enabled = girl_send_messages_enabled
				this.activeThread.girl_send_messages_enabled = girl_send_messages_enabled
			} catch (e) {
				throw new Error(e)
			}
		},
		resetMessages() {
			if (this.messagesController) this.messagesController.abort()
			this.updateMessages([])
			this.toggleActiveThread(null)
			this.messagesMeta = null
			this.showSubscribeModal = false
			this.messagesLoaded = false
			this.isLoadingMessages = false
			this.showSubscribeModal = false
			this.girlProfile = null
		}
	}
})
