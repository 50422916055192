<template>
	<dialog-component v-if="showCommentsDialog" class="comments-dialog">
		<template #header-title>
			<div v-if="!isLoading">
				<div v-if="showLikeButton && comments.meta.total > 0" class="text-red">
					{{ $t('post.bestComment') }}
				</div>
				<div v-else>
					<span v-if="comments.meta.total > 0" class="text-lower">
						{{ `${total_comments} ${totalCommentsString}` }}</span
					>
					<span v-else>{{ $t('post.noComments') }}</span>
				</div>
			</div>
		</template>
		<div ref="commentsList">
			<infinite-scroll
				:class="[{ 'comment-field-showed': isConsumer || isGuest }]"
				v-if="!isLoading"
				:load-function="getMorePostComments"
				target-element-selector=".dialog-body"
			>
				<comment-component
					v-for="comment in comments.data"
					:key="`comment${comment.id}`"
					:preview-letters-count="70"
					:author-id="comment.comment.author.id"
					:nickname="comment.comment.author.nickname"
					:avatar="comment.comment.author.avatar"
					:has-girl-subscription="comment.comment.author?.has_girl_subscription"
					:text="comment.comment.text"
					:created-at="comment.comment.created_at"
					:liked-at-text="comment.comment.liked_time_as_text"
					:liked-avatar="comment.post.author.avatar"
					:on-click-nickname-function="getClickNickNameFunction(comment.comment.author.nickname)"
					:has-acting-girl-following="comment.comment.author.has_acting_girl_following"
					:has-acting-girl-subscription="comment.comment.author.has_acting_girl_subscription"
					:is-adult="comment.comment.author.is_adult"
					:mentioned="comment.comment.mentioned"
					:ref="`#comment-${comment.id}`"
					:show-liked-post="false"
					@click-link="clickLink"
				>
					<template #append v-if="showLikeButton">
						<span class="like-icon sm" @click="setLikeToComment(comment.id)" />
					</template>
				</comment-component>
			</infinite-scroll>
			<template v-else>
				<placeholder-comment v-for="i in total_comments" :key="`pc${i}`" />
			</template>
			<comment-field v-if="isConsumer || isGuest" ref="commentField" @comment-created="commentCreated" />
		</div>
	</dialog-component>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { usePostStore } from '@/stores/post'
import { useHomeStore } from '@/stores/home'
import { useGirlProfileStore } from '@/stores/girlProfile'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import InfiniteScroll from '@/components/InfiniteScroll.vue'
import CommentComponent from '@/components/CommentComponent.vue'
import CommentField from '@/components/CommentField.vue'
import PlaceholderComment from '@/components/placeholders/PlaceholderComment.vue'

export default {
	name: 'CommentsDialog',
	components: { DialogComponent, CommentField, InfiniteScroll, CommentComponent, PlaceholderComment },
	props: {
		showCommentsDialog: {
			type: Boolean
		}
	},
	watch: {
		showCommentsDialog(newVal) {
			if (newVal) {
				window.addEventListener('popstate', this.handlePopState)
			} else {
				window.removeEventListener('popstate', this.handlePopState)
			}
		}
	},
	computed: {
		...mapWritableState(usePostStore, ['girl_liked_any_comment']),
		...mapState(usePostStore, ['id', 'author', 'comments', 'total_comments', 'isLoading']),
		...mapState(useGirlProfileStore, { currentUserId: 'id' }),
		isConsumer() {
			return this.$auth.check('consumer')
		},
		isGirl() {
			return this.$auth.check('girl')
		},
		isGuest() {
			return JSON.parse(this.$auth.remember())?.role === 'guest'
		},
		isOwnProfile() {
			return this.currentUserId === this.author.id
		},
		totalCommentsString() {
			return this.$t('comment', this.total_comments)
		},
		showLikeButton() {
			return this.isGirl && this.isOwnProfile && !this.girl_liked_any_comment
		}
	},
	methods: {
		...mapActions(useHomeStore, ['setLastCommentForPost']),
		...mapActions(usePostStore, ['getMorePostComments', 'likeComment']),
		getClickNickNameFunction(nickname) {
			if (!this.isGirl) {
				return () => this.pasteNickname(nickname)
			}
			return undefined
		},
		pasteNickname(nickname) {
			this.$refs.commentField.pasteSubstring(`@${nickname} `)
		},
		commentCreated() {
			this.setLastCommentForPost(this.comments?.data[0])
			this.$refs.commentsList.scrollIntoView()
			this.$refs.commentsList.scrollTo(0, 10)
		},
		setLikeToComment(commentId) {
			this.likeComment(commentId, this.id)
		},
		closeDialog(link = false) {
			this.$emit('close', link)
		},
		handlePopState() {
			this.closeDialog()
		},
		clickLink() {
			this.closeDialog(true)
		}
	},
	beforeUnmount() {
		window.removeEventListener('popstate', this.handlePopState)
	}
}
</script>

<style lang="scss">
.comments-dialog {
	& .dialog {
		display: flex;
		flex-direction: column;
		overflow: unset;
	}
	.dialog-header {
		min-height: 40px;
	}
	& .dialog-body {
		overflow-y: auto;
		height: 100%;
		padding: 10px;
	}
	& .comment {
		padding: 0 10px;
		& > .avatar-circle {
			margin-right: 12px;
		}
	}
	& .comment-field-showed {
		padding-bottom: 45px;
	}
	.comment-input-wrap {
		position: static;
	}
	.comment-validation {
		margin: 5px 20px;
	}
}
</style>
