<template>
	<div
		@click="onSelect"
		class="history-item"
		:class="{
			active: filter?.currentGirl === id,
			opacity: waiting_subscription_result
		}"
	>
		<div class="image-container relative">
			<div class="image placeholder-bg">
				<img rel="preload" fetchpriority="high" :src="avatar" alt="" />
			</div>
			<div class="history-item-spinner" v-if="waiting_subscription_result">
				<loader-component size="24px" />
			</div>
			<template v-if="$config.allowFollowing">
				<badge-fan v-if="has_girl_subscription" />
				<badge-following v-else-if="has_girl_following && $config.allowFollowing" />
			</template>
			<div class="online-status" v-show="showOnlineStatus" />
		</div>
		<div class="history-item-name">
			{{ nickname }}
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useHomeStore } from '@/stores/home'
import { useCommonStore } from '@/stores/common'
import { useCurrentUserStore } from '@/stores/user'
import BadgeFollowing from '@/components/BadgeFollowing.vue'
import BadgeFan from '@/components/BadgeFan.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
	name: 'FilterCard',
	components: { BadgeFan, BadgeFollowing, LoaderComponent },
	props: {
		nickname: { type: String, default: '' },
		avatar: { type: String, default: '' },
		id: { type: Number, default: 0 },
		role: { type: String, default: '' },
		waiting_subscription_result: { type: Boolean, default: false },
		has_girl_following: { type: Boolean, default: false },
		has_girl_subscription: { type: Boolean, default: false }
	},
	computed: {
		...mapState(useHomeStore, ['filter']),
		...mapState(useCommonStore, {
			getUserOnlineStatus: 'getUserOnlineStatus'
		}),
		...mapState(useCurrentUserStore, {
			currentRole: 'userRole'
		}),
		showOnlineStatus() {
			return this.$config.onlineStatusFeature && this.id && this.getUserOnlineStatus(this.role, this.id)
		}
	},
	methods: {
		...mapActions(useHomeStore, ['setFilter']),
		onSelect() {
			this.setFilter(this.id)
		}
	}
}
</script>

<style lang="scss" scoped>
.image-container {
	.badge-following,
	.badge-fan {
		position: absolute;
		left: -3px;
		bottom: -3px;
		border: 2px solid $color-black;
		box-sizing: content-box;
	}

	.online-status {
		position: absolute;
		width: 10px;
		height: 10px;
		border-radius: 100px;
		background-color: #29cc6a;
		border: 2px solid $color-black;
		right: 0;
		bottom: 0;
	}
}

.history-item-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 18px;
	display: flex;
	//margin-left: 50%;
}

.history-item .image {
	display: flex;
	overflow: hidden;
	justify-content: center;
}

.image img {
	height: 100%;
	object-fit: contain;
}

.history-item {
	@media (min-width: $screen-desktop) {
		cursor: pointer;
		transition: opacity 0.3s ease-in-out;
		&:hover {
			opacity: 0.7;
		}
	}
}
</style>
