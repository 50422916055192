import { http } from '@/http/index'

async function getFans(girlId, params) {
	return http.get(`/girls/${girlId}/fans-with-likes`, { params }).then((response) => response.data)
}

async function getMoreFans(url, params) {
	return http.get(url, { params }).then((response) => response.data)
}

export { getFans, getMoreFans }
