import { defineStore } from 'pinia'
import { usePaidMessageApiStore } from '@/stores/messenger/paid-message/api'
import { useMessengerStore } from '@/stores/messenger'

export const usePaidMessageStore = defineStore('paid-message', () => {
	const paidMessageApiStore = usePaidMessageApiStore()
	const messengerStore = useMessengerStore()

	async function createNewPaidMessage(type) {
		const { id } = await paidMessageApiStore.createNewPaidMessage(type)
		return id
	}

	async function updatePaidMessage(messageId, data) {
		await paidMessageApiStore.updatePaidMessage(messageId, data)
	}

	async function setPaidMessageCover(messageId, data) {
		await paidMessageApiStore.setCoverForPaidMessage(messageId, data)
	}

	async function uploadPaidMedia(type, messageId, data, signal) {
		try {
			return paidMessageApiStore.uploadPaidMedia(type, messageId, data, signal)
		} catch (e) {
			throw new Error(e)
		}
	}

	async function deleteFile(messageId, attachmentId) {
		await paidMessageApiStore.deleteFile(messageId, attachmentId)
	}

	async function getPaidMessageFreeAccess(message) {
		try {
			const index = messengerStore.messagesList.findIndex((m) => m.id === message.id)
			message.paid_message.payment_status = 'pending_payment'
			messengerStore.messagesList.splice(index, 1, message)
			message.paid_message = await paidMessageApiStore.getPaidMessageFreeAccess(message.id)
			messengerStore.messagesList.splice(index, 1, message)
		} catch (e) {
			throw new Error(e)
		}
	}

	async function sendPaidMessage(threadId, messageId) {
		await paidMessageApiStore.publishPaidMessage(threadId, messageId)
	}

	async function deletePaidMessage(paidMessageId, threadId, messageId) {
		await paidMessageApiStore.deletePaidMessage(paidMessageId, threadId, messageId)
	}

	async function renewPaidMessage(threadId, messageId) {
		const message = await paidMessageApiStore.getMessage(threadId, messageId)
		messengerStore.newMessage(message, message.owner_id)
	}

	return {
		renewPaidMessage,
		deletePaidMessage,
		sendPaidMessage,
		getPaidMessageFreeAccess,
		deleteFile,
		uploadPaidMedia,
		setPaidMessageCover,
		createNewPaidMessage,
		updatePaidMessage
	}
})
