import { onMounted, onUnmounted, ref } from 'vue'

const subscribers = new Set()
const windowWidth = ref(window.innerWidth)
const debounceTime = 200
let resizeTimeout = null

const debouncedUpdate = () => {
	if (resizeTimeout) clearTimeout(resizeTimeout)
	resizeTimeout = setTimeout(() => {
		windowWidth.value = window.innerWidth
		subscribers.forEach((cb) => cb())
	}, debounceTime)
}

export const useResize = (cb) => {
	onMounted(() => {
		if (subscribers.size === 0) {
			window.addEventListener('resize', debouncedUpdate)
		}
		subscribers.add(cb)
	})

	onUnmounted(() => {
		subscribers.delete(cb)
		if (subscribers.size === 0) {
			window.removeEventListener('resize', debouncedUpdate)
		}
	})

	return windowWidth
}
