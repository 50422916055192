import { defineStore } from 'pinia'
import posts, { getMoreData } from '@/stores/helper'
import postsApi from '@/http/posts'
import girlsApi from '@/http/girl'
import { useCommonStore } from '@/stores/common'

export const useSearchStore = defineStore('search', {
	state: () => ({
		searchValue: '',
		posts: null,
		girls: null,
		recommendedPosts: null,
		isLoading: false,
		postsLoading: false,
		girlsLoading: false,
		activeTab: 'posts',
		commonStore: useCommonStore()
	}),
	getters: {
		postsHasLoaded: (state) => state.posts !== null,
		recommendedPostsHasLoaded: (state) => state.recommendedPosts !== null,
		isEnoughCharactersForSearch() {
			return this.searchValue?.length > 3
		}
	},
	actions: {
		resetValue() {
			this.searchValue = ''
			this.posts.data = []
		},
		updateLikes(post) {
			posts.findPostAndUpdateLikesValue(this.posts, post)
			posts.findPostAndUpdateLikesValue(this.recommendedPosts, post)
		},
		applyBlurToPostsOnlyForFans(girlId) {
			if (this.posts) {
				posts.mediaContentVisibleOnlyForFans(this.posts.data, girlId)
			}
			if (this.recommendedPosts) {
				posts.mediaContentVisibleOnlyForFans(this.recommendedPosts.data, girlId)
			}
		},
		removeBlurFromPosts(girlId) {
			if (this.posts) {
				posts.medialContentVisibleForAllConsumers(this.posts.data, girlId)
			}
			if (this.recommendedPosts) {
				posts.medialContentVisibleForAllConsumers(this.recommendedPosts.data, girlId)
			}
		},
		turnOnWaitingSubscription(girlId) {
			if (this.posts) {
				posts.turnOnWaitingSubscription(this.posts.data, girlId)
			}
			if (this.recommendedPosts) {
				posts.turnOnWaitingSubscription(this.recommendedPosts.data, girlId)
			}
		},
		async getRecommendedPosts() {
			this.isLoading = true
			const result = await postsApi.getRecommendedPost()
			if (result) {
				this.recommendedPosts = result
				this.commonStore.mapPostEntityOnlineStatus(result.data)
			}
			this.isLoading = false
		},
		async getMoreRecommendedPosts() {
			await getMoreData(
				this.recommendedPosts,
				postsApi.getMoreRecommendedPost,
				{},
				this.commonStore.mapPostEntityOnlineStatus
			)
		},
		async searchPostsByDescription() {
			if (this.isEnoughCharactersForSearch) {
				this.postsLoading = true
				const result = await postsApi.searchPostsByDescription(this.searchValue)
				if (result) {
					this.posts = result
				}
			} else {
				this.posts = null
			}
			this.postsLoading = false
		},
		async girlsSearch() {
			if (this.isEnoughCharactersForSearch) {
				this.girlsLoading = true
				const result = await girlsApi.searchGirls({ search: this.searchValue })
				if (result.data) {
					this.girls = result.data
				}
			} else {
				this.girls = null
			}
			this.girlsLoading = false
		},
		async getMorePosts() {
			await getMoreData(this.posts, postsApi.getMorePosts, {}, this.commonStore.mapPostEntityOnlineStatus)
		},
		async getMoreGirls() {
			await getMoreData(this.girls, girlsApi.getMorePosts, {}, this.commonStore.mapPostEntityOnlineStatus)
		},
		removePostFromList(postId) {
			// posts.removePostFromList(this.posts.data, postId);
			posts.removePostFromList(this.recommendedPosts, postId)
		},
		setActiveTab(tab) {
			this.activeTab = tab
		},
		resetPosts() {
			this.posts = null
		},
		resetGirls() {
			this.girls = null
		}
	}
})
