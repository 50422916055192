<template>
	<div v-if="$auth.check('consumer')" class="history">
		<template v-if="!followingGirlsHasLoaded">
			<div class="history__placeholder">
				<placeholder-filter-card v-for="i in 3" :key="`pfc${i}`" />
			</div>
		</template>
		<template v-else>
			<swiper
				:modules="swiperModules"
				:slides-per-view="'auto'"
				:free-mode="true"
				:slides-per-group="4"
				:space-between="12"
				navigation
			>
				<swiper-slide v-if="showFilterCardButton" class="history-swiper-slide">
					<filter-card-button @click="onRedirect" />
				</swiper-slide>
				<swiper-slide v-for="girl in followingGirls?.data" :key="`fg${girl.id}`" class="history-swiper-slide">
					<filter-card v-bind="girl" />
				</swiper-slide>
			</swiper>
		</template>
	</div>
</template>

<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useProfileStore } from '@/stores/profile'

import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/swiper.min.css'

import PlaceholderFilterCard from '@/components/placeholders/PlaceholderFilterCard.vue'
import FilterCard from '@/components/FilterCard.vue'
import FilterCardButton from '@/components/FilterCardButton.vue'

const profileStore = useProfileStore()
const { followingGirls, followingGirlsHasLoaded, has_subscription_to_service } = storeToRefs(profileStore)

const showFilterCardButton = computed(() => followingGirlsHasLoaded && !has_subscription_to_service)

const onRedirect = () => {
	window.location.href = this.$config.subsCardUrl
}

const swiperModules = [Navigation]
</script>

<style lang="scss">
.history {
	&__placeholder {
		display: flex;
		flex-flow: row nowrap;
	}

	.history-swiper-slide {
		width: 78px;

		img {
			width: auto;
		}
	}

	&:hover {
		.swiper-button-next:not(.swiper-button-disabled),
		.swiper-button-prev:not(.swiper-button-disabled) {
			opacity: 1;
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
		transition:
			opacity 0.2s ease-in-out,
			background-color 0.2s ease-in-out;
		cursor: pointer;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		background: rgba(5, 6, 7, 0.7);
		opacity: 0;

		@media (max-width: $screen-desktop) {
			display: none;
		}

		&.swiper-button-disabled {
			pointer-events: none;
			opacity: 0;
		}

		&:before {
			width: 7px;
			height: 7px;
		}
	}

	.swiper-button-next {
		right: 10px;

		&:before {
			transform: translate3d(-67%, -48%, 0px) rotate(45deg);
		}
	}
	.swiper-button-prev {
		left: 10px;

		&:before {
			transform: translate3d(-35%, -50%, 0px) rotate(225deg);
		}
	}
}
</style>
