<template>
	<div class="avatar" :style="avatarSize">
		<div class="avatar-circle placeholder-bg">
			<img v-lazy="imagePath" :src="imagePath" alt="Аватар" />
		</div>
		<div class="online-status" :style="onlineStatusStyles" v-show="showOnlineStatus" />
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useCommonStore } from '@/stores/common'
import { useCurrentUserStore } from '@/stores/user'

export default {
	name: 'AvatarCircle',
	props: {
		imagePath: {
			type: String,
			default: ''
		},
		userId: {
			type: [Number, null],
			default: null
		},
		userRole: {
			type: String,
			default: ''
		},
		size: {
			type: Number,
			default: 40
		},
		allowSelfStatus: {
			type: Boolean,
			default: false
		},
		borderColor: {
			type: String,
			default: '#191c22'
		}
	},
	computed: {
		...mapState(useCommonStore, {
			getUserOnlineStatus: 'getUserOnlineStatus'
		}),
		...mapState(useCurrentUserStore, {
			currentUserId: 'currentUserId'
		}),
		avatarSize() {
			return {
				width: `${this.size}px`,
				height: `${this.size}px`,
				minHeight: `${this.size}px`,
				minWidth: `${this.size}px`
			}
		},
		onlineStatusStyles() {
			switch (this.size) {
				case 30: {
					return {
						width: '10px',
						height: '10px',
						bottom: 0,
						right: '-2px',
						borderColor: this.borderColor
					}
				}
				case 50: {
					return {
						width: '12px',
						height: '12px',
						bottom: '1px',
						right: '1px',
						borderColor: this.borderColor
					}
				}
				case 60: {
					return {
						width: '12px',
						height: '12px',
						bottom: 0,
						right: '4px',
						borderColor: this.borderColor
					}
				}
				case 80: {
					return {
						width: '16px',
						height: '16px',
						bottom: 0,
						right: '6.5px',
						borderColor: this.borderColor,
						borderWidth: '3px'
					}
				}
				case 100: {
					return {
						width: '18px',
						height: '18px',
						bottom: '4px',
						right: '4px',
						borderColor: this.borderColor,
						borderWidth: '4px'
					}
				}
				default: {
					return {
						width: '10px',
						height: '10px',
						bottom: '-1px',
						right: '1px',
						borderColor: this.borderColor
					}
				}
			}
		},
		showSelfStatus() {
			return this.allowSelfStatus || this.userId !== this.currentUserId
		},
		showOnlineStatus() {
			return (
				this.$config.onlineStatusFeature &&
				this.showSelfStatus &&
				this.userId &&
				this.getUserOnlineStatus(this.userRole, this.userId)
			)
		}
	}
}
</script>

<style lang="scss" scoped>
.avatar {
	position: relative;
	width: 40px;
	height: 40px;

	.online-status {
		position: absolute;
		border-radius: 100px;
		border: 2px solid;
		background-color: #29cc6a;
		z-index: 1;
	}
}

.avatar-circle {
	border-radius: 100%;
	background-size: 100%;
	overflow: hidden;
	width: 100%;
	height: 100%;
	min-width: 30px;
	min-height: 30px;

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
</style>
