import { http } from '@/http/index'
import AppConfig from '@/config.class'

async function girlLogout() {
	return http.post(AppConfig.girlLogoutUrl).then((response) => response.data)
}

async function consumerLogout() {
	return http.post(AppConfig.consumerLogoutUrl).then((response) => response.data)
}

export { girlLogout, consumerLogout }
