<template>
	<div class="main-content">
		<history-component v-if="$auth.check('consumer')" />
		<div class="feed flex column start">
			<infinite-scroll :load-function="getMorePosts" :show-p-w-a="true" save-scroll>
				<placeholder-post v-if="!postsHasLoaded" />
				<post-component v-for="post in posts?.data" :key="`fp${post.id}`" v-bind="post" />
			</infinite-scroll>
		</div>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { useHomeStore } from '@/stores/home'
import { useProfileStore } from '@/stores/profile'
import InfiniteScroll from '@/components/InfiniteScroll.vue'
import PlaceholderPost from '@/components/placeholders/PlaceholderPost.vue'
import HistoryComponent from '@/components/HistoryComponent.vue'

export default {
	name: 'HomeView',
	components: {
		PlaceholderPost,
		PostComponent: defineAsyncComponent(() => import('@/components/PostComponent.vue')),
		InfiniteScroll,
		HistoryComponent
	},
	computed: {
		...mapState(useProfileStore, [
			'followingGirls',
			'followingGirlsHasLoaded',
			'has_subscription_to_service',
			'hasCardPayment'
		]),
		...mapState(useHomeStore, ['posts', 'postsHasLoaded']),
		showFilterCardButton() {
			return this.followingGirlsHasLoaded && !this.has_subscription_to_service
		},
		isConsumer() {
			return this.$auth.check('consumer')
		}
	},
	methods: {
		...mapActions(useHomeStore, ['getPosts', 'getMorePosts']),
		...mapActions(useProfileStore, {
			getSubscriptionTypes: 'getSubscriptionTypes',
			getFollowingGirls: 'getFollowingGirls'
		}),
		onRedirect() {
			window.location.href = this.$config.subsCardUrl
		}
	},
	async mounted() {
		if (!this.posts) {
			this.getPosts()
		}
		if (this.isConsumer) {
			await this.getFollowingGirls()
			await this.getSubscriptionTypes()
		}
	}
}
</script>
