<template>
	<div class="like-counter" @click="showBalanceNotification">
		<span class="text-medium">{{ balance }}</span>
		<span class="like-counter-icon like-icon" :class="{ active: balance }" />
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useProfileStore } from '@/stores/profile'
import { useModalStore } from '@/stores/modal'

export default {
	name: 'LikeBalance',
	computed: {
		...mapState(useProfileStore, ['balance'])
	},
	methods: {
		...mapActions(useModalStore, ['showBalanceNotification'])
	}
}
</script>

<style scoped lang="scss">
.like-counter {
	cursor: pointer;
}
</style>
