import { defineStore } from 'pinia'
import messengerApi from '@/http/messenger/index'

export const usePaidMessageApiStore = defineStore('paid-message-api', () => {
	const createNewPaidMessage = (type) => messengerApi.createPaidMessage(type)
	const updatePaidMessage = (messageId, data) => messengerApi.updatePaidMessage(messageId, data)
	const setCoverForPaidMessage = (messageId, data) => messengerApi.setCoverForPaidMessage(messageId, data)
	const uploadPaidMedia = (type, messageId, data, signal) => messengerApi.uploadPaidMedia(type, messageId, data, signal)
	const deleteFile = (messageId, attachmentId) => messengerApi.deleteMedia(messageId, attachmentId)
	const getPaidMessageFreeAccess = (messageId) => messengerApi.getPaidMessageFreeAccess(messageId)
	const publishPaidMessage = (threadId, messageId) => messengerApi.publishPaidMessage(threadId, messageId)
	const deletePaidMessage = (paidMessageId, threadId, messageId) =>
		messengerApi.deletePaidMessage(paidMessageId, threadId, messageId)
	const getMessage = (threadId, messageId) => messengerApi.getMessage(threadId, messageId)

	return {
		getMessage,
		deletePaidMessage,
		publishPaidMessage,
		getPaidMessageFreeAccess,
		deleteFile,
		uploadPaidMedia,
		createNewPaidMessage,
		updatePaidMessage,
		setCoverForPaidMessage
	}
})
