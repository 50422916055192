import { http } from '@/http/index'

async function getThreadsList(params) {
	return http.get(`/messenger/threads`, { params }).then((response) => response.data)
}

async function getMoreThreads(id, params) {
	return http.get(`/messenger/threads/page/${id}`, { params }).then((response) => response.data)
}

export { getThreadsList, getMoreThreads }
