import Pusher from 'pusher-js/with-encryption'
import Echo from 'laravel-echo'
import { useMessengerStore } from '@/stores/messenger'
import { usePaidMessageStore } from '@/stores/messenger/paid-message'
import AppConfig from '@/config.class'
import { useCommonStore } from '@/stores/common'
import { useGirlStore } from '@/stores/girl'

window.Pusher = Pusher
function connect(token) {
	window.Echo = new Echo({
		broadcaster: 'reverb',
		key: AppConfig.reverbAppKey,
		wsHost: AppConfig.reverbHost,
		wsPort: AppConfig.reverbPort,
		wssPort: AppConfig.reverbPort,
		forceTLS: AppConfig.reverbScheme === 'https',
		enabledTransports: ['ws', 'wss'],
		authEndpoint: AppConfig.broadcastingAuth,
		auth: {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}
	})
}

function subscribe(role, id) {
	const commonStore = useCommonStore()
	const subscriptionsChannel = window.Echo.channel(`subscriptions.${id}`)
	const activityChannel =
		role === 'girl'
			? window.Echo.private('activity-status-for-girls')
			: window.Echo.private('activity-status-for-consumers')
	const channel = window.Echo.private(`messenger.${role}.${id}`)
	const onlineChannel = window.Echo.private(`${role}.${id}`)
	const key = `${role}_id`

	setInterval(() => {
		onlineChannel.whisper('keep-online', { status: 'keep_online', [key]: id })
	}, 1000 * 60)

	window.onbeforeunload = () => {
		onlineChannel.whisper('offline', { status: 'offline', [key]: id })
		return undefined
	}

	activityChannel.listen('.user-went-online', (data) => {
		if (data.consumer) {
			commonStore.setUserOnlineStatus('consumer', data.consumer.id, true)
		}
		if (data.girl) {
			commonStore.setUserOnlineStatus('girl', data.girl.id, true)
		}
	})

	activityChannel.listen('.user-went-offline', (data) => {
		if (data.consumer) {
			commonStore.setUserOnlineStatus('consumer', data.consumer.id, false)
		}
		if (data.girl) {
			commonStore.setUserOnlineStatus('girl', data.girl.id, false)
		}
	})

	subscriptionsChannel.listen('.consumer.subscribed', () => {
		window.location.reload()
	})

	subscriptionsChannel.listen('.consumer.unsubscribed', () => {
		useGirlStore().disableSubscriptionState()
	})

	channel.listen('.new.message', (data) => {
		useMessengerStore().newMessage(data, id)
	})

	channel.listen('.message.archived', (data) => {
		useMessengerStore().removeRoomMessage(data)
	})

	channel.listen('.thread.archived', (data) => {
		useMessengerStore().removeThread(data)
	})

	channel.listen('.new.thread', (data) => {
		useMessengerStore().newThread(data)
	})
	channel.listen('.thread.read', (data) => {
		useMessengerStore().threadRead(data)
	})
	channel.listen('.paid-message.content-ready', (data) => {
		usePaidMessageStore().renewPaidMessage(data.messenger_thread_uuid, data.messenger_message_uuid)
	})

	channel.listen('.paid-message.payment-status-update', (data) => {
		usePaidMessageStore().renewPaidMessage(data.messenger_thread_uuid, data.messenger_message_uuid)
	})
	channel.listen('.unread_threads_count', (data) => {
		useMessengerStore().setUnreadThreadsCount(data.unread_threads_count)
	})
}

function subscribeThread(id) {
	const channel = window.Echo.channel(`presence-messenger.thread.${id}`)
	channel.listen('.message.edited', (data) => {
		useMessengerStore().updateEditedMessage(data)
	})
	channel.listen('.thread.avatar', (data) => {
		useMessengerStore().threadAvatar(data)
	})
	channel.listen('.reaction.added', (data) => {
		useMessengerStore().updateReactions(data)
	})
	channel.listen('.reaction.removed', (data) => {
		useMessengerStore().updateReactions(data)
	})
}

function checkConnection() {
	return window.Echo
}

export default { connect, subscribe, subscribeThread, checkConnection }
