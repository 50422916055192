import { computed, ref } from 'vue'
import { useResize } from '@/composables/useResize'

export const useBreakpoints = () => {
	const mobile = 980
	const windowWidth = ref(window.innerWidth)

	useResize(() => {
		windowWidth.value = window.innerWidth
	})

	return {
		isMobile: computed(() => windowWidth.value <= mobile),
		isDesktop: computed(() => windowWidth.value > mobile)
	}
}
